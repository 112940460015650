@import "../font/font.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background-v1: 0, 0%, 94%, 1;
    --background-v2: 0, 0%, 100%, 1;
    --text-v1: 215, 17%, 55%, 1;
    --text-v2: 0, 0%, 24%, 1;
    --border-v1: 214, 25%, 81%, 1;
    --border-v2: 210, 40%, 96%, 0.5;
  }
  .dark {
    --background-v1: 0, 0%, 12%, 1;
    --background-v2: 0, 0%, 18%, 1;
    --text-v1: 215, 17%, 75%, 1;
    --text-v2: 0, 0%, 90%, 1;
    --border-v1: 214, 25%, 40%, 1;
    --border-v2: 214, 25%, 40%, 1;
  }
}

* {
  @apply p-0 m-0 box-border scroll-smooth font-inter;
}

body {
  @apply overflow-x-hidden;
}
/* 
::-webkit-scrollbar {
  @apply w-1 h-1 bg-backgroundV2;
}
::-webkit-scrollbar-track {
  @apply w-1 bg-backgroundV1;
}
::-webkit-scrollbar-thumb {
  @apply w-1 bg-theme;
}
.hidden-scroll::-webkit-scrollbar{
   @apply hidden
} */

.scroll-sm::-webkit-scrollbar {
  @apply w-0.5 h-0.5;
}
/* .animate-in{
  animation-name: enter;
  animation-duration: .80s;
}

@keyframes enter {
  0% {
      opacity: 1;
      transform: translate3d(var(--tw-enter-translate-x,0),var(--tw-enter-translate-y,0),0) scale3d(var(--tw-enter-scale,1),var(--tw-enter-scale,1),var(--tw-enter-scale,1)) rotate(var(--tw-enter-rotate,0))
  }
}

@keyframes exit {
  to {
      opacity: var(--tw-exit-opacity,1);
      transform: translate3d(var(--tw-exit-translate-x,0),var(--tw-exit-translate-y,0),0) scale3d(var(--tw-exit-scale,1),var(--tw-exit-scale,1),var(--tw-exit-scale,1)) rotate(var(--tw-exit-rotate,0))
  }
} */

#cover-spin {
  background-color: transparent !important;
}
.accordian-custom[data-state="open"] svg.icon-chev {
  @apply rotate-90;
}
table.table-has-border-space thead tr th:not(:last-child),
table.table-has-border-space tbody tr td:not(:last-child) {
  @apply border-e-0;
}
table.table-has-border-space thead tr th:not(:first-child),
table.table-has-border-space tbody tr td:not(:first-child) {
  @apply border-s-0;
}

.select.css-13cymwt-control {
  @apply !rounded-[12px];
}

ul li.news-link-wrapper:first link {
  @apply pt-0;
}
.custome-datepicker .dow {
  @apply w-full h-6 text-xs !flex items-center justify-center;
}
.custome-datepicker div.grid.w-64 {
  @apply w-full gap-1;
}
.custome-datepicker div.flex.justify-between {
  @apply mb-4;
}
.custome-datepicker div.flex.justify-between button {
  @apply p-[4px];
}
.custome-datepicker div.flex.justify-between button:nth-child(2) {
  @apply text-xs flex-grow;
}
.custome-datepicker div.p-1 {
  @apply p-0;
}
.main-date-picker-wrapper div.relative .absolute.inset-y-0 {
  @apply left-[unset] right-3;
}

/* .css-1hb7zxy-IndicatorsContainer {
  display: none!important;
  
} */
.css-1fdsijx-ValueContainer {
  padding: 7px 10px !important;
}

.css-1xc3v61-indicatorContainer {
  color: #8b8a8a!important;
}
.css-1u9des2-indicatorSeparator {
  display: none !important;
}
.css-13cymwt-control {
  border-radius: 12px !important;
}


.react_select__option {
  font-size: 13px !important;
}
.react_select__single-value {
  font-size: 12px !important;
}

.react_select__option--is-selected{
  background-color: #4ea3fd !important;
}

.react_select__placeholder {
  color: black !important;
}

.react_select__control {
  padding: 0px !important;
}

.react_select__menu-list {
  font-size: 12px !important;
  padding: 0px !important;
}
.react_select__menu-list .react_select__control {
  border-radius: 0px !important;
  border: 0px !important;
  border-bottom: 1px solid #e5e5e5 !important;
}

.react_select__control--is-disabled {
  /* background-color: #f9f9f9 !important; */
  font-size: 12px !important;
}