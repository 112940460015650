.react-datepicker-wrapper{
    width: 100%;
}

/* Add this CSS to your stylesheet */
input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }