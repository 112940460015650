.text_editor {
  $color_1: #f8f8f2;
  $color_2: #444;
  $color_3: #ccc;
  $color_4: #06c;
  $font-family_1: Georgia, Times New Roman, serif;
  $font-family_2: Monaco, Courier New, monospace;
  $font-family_3: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  $background-color_1: white;
  $background-color_2: #f0f0f0;
  $background-color_3: #23241f;
  $background-color_4: #fff;
  $background-color_5: #000;
  $border-color_1: #ccc;
  $border-color_2: #000;
  $grid-size: 30px;

  #contentcontainer {
    p {
      margin: 0;
    }
    ul {
      margin: 0;
    }
    ol {
      margin: 0;
    }
    dl {
      margin: 0;
    }
    h1 {
      margin: 0;
      font-size: 2em;
    }
    h2 {
      margin: 0;
      font-size: 1.5em;
    }
    h3 {
      margin: 0;
      font-size: 1.17em;
    }
    h4 {
      margin: 0;
      font-size: 1em;
    }
    h5 {
      margin: 0;
      font-size: 0.83em;
    }
    h6 {
      margin: 0;
      font-size: 0.67em;
    }
    blockquote {
      margin: 0;
      border-left: 4px solid #ccc;
      margin-bottom: 5px;
      margin-top: 5px;
      padding-left: 16px;
    }
  }

  /* Quil Css */
  /** Apply snow theme to app */
  p {
    margin: 0;
  }
  ul {
    margin: 0;
  }
  ol {
    margin: 0;
  }
  dl {
    margin: 0;
  }
  h1 {
    margin: 0;
    font-size: 2em;
  }
  h2 {
    margin: 0;
    font-size: 1.5em;
  }
  h3 {
    margin: 0;
    font-size: 1.17em;
  }
  h4 {
    margin: 0;
    font-size: 1em;
  }
  h5 {
    margin: 0;
    font-size: 0.83em;
  }
  h6 {
    margin: 0;
    font-size: 0.67em;
  }
  blockquote {
    margin: 0;
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }
  pre {
    margin: 0;
    background-color: $background-color_2;
    border-radius: 3px;
    white-space: pre-wrap;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px 10px;
  }
  form {
    margin: 0;
  }
  table {
    margin: 0;
  }
  .ql-toolbar.ql-snow {
    border: none;
  }
  .grid-layout {
    // height: 100%;
    background-image: repeating-linear-gradient(
        0deg,
        transparent,
        transparent calc(30px - 1px),
        #ddd calc(30px - 1px),
        #ddd 30px
      ),
      repeating-linear-gradient(
        -90deg,
        transparent,
        transparent calc(30px - 1px),
        #ddd calc(30px - 1px),
        #ddd 30px
      );
    background-size: 30px 30px;
    // background: repeating-linear-gradient(90deg, transparent, transparent 30px, #ddd 30px, #ddd 30px, transparent 30px, transparent 30px, transparent 30px), repeating-linear-gradient(0deg, transparent, transparent 30px, #ddd 30px, #ddd 30px, transparent 30px, transparent 30px, transparent 30px);
    // pointer-events: none;
  }
  // .Vertical {
  //   width: 80%;
  //   height: 100vh;
  // }
  // .Horizontal {
  //   width: 100%;
  //   height: 80vh;
  // }

  .Portrait {
    margin: 0.75rem 0 !important;
  //   width: 100%;
  //   height: 80vh;
  }

  // .Landscape {
  //   width: calc(80vh * 9 / 16);
  //   height: 100%;
  // }
  #changeRetio {
    width: 100%;
    height: 80vh;
  }
  .canvas-container {
    // width: 100%;
    // height: 80vh;
    margin: 0 auto;
    margin-top: 10px;
    position: relative;
    overflow: hidden;
    // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    // background-color: $background-color_1;
  }
  .toolbar-container {
    display: flex;
    width: 100%;
    background: white;
    margin-bottom: 10px;
    user-select: none;
  }
  .toolbar-item {
    padding: 8px 5px;
    cursor: pointer;
    display: flex;
    margin-right: 2px;
    border-radius: 3px;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    button {
      font-size: 12px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      &:hover {
        // background: rgb(228, 228, 228);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      }
    }
    // &:hover {
    //   // background: rgb(228, 228, 228);
    //   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    // }
  }
  .ql-editor {
    // padding: 0;

    overflow-y: visible !important;
  }
  .quill-container {
    width: 100%;
    height: 100%;
    width: 100%;
    .ql-editor {
      // padding: 0;
      box-sizing: border-box !important;
      line-height: 0 !important;
      height: 100% !important;
      outline: none !important;
      overflow-y: visible !important;
      // padding: 12px 15px !important;
      // tab-size: 4 !important;
      // -moz-tab-size: 4 !important;
      text-align: left !important;
      white-space: pre-wrap !important;
      word-wrap: break-word !important;
    }
    .ql-container {
      border: 0 !important;
    }
  }
  .item-container {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  .showHandles {
    .handle {
      display: block;
    }
  }
  .handle {
    display: none;
    width: 10px !important;
    height: 10px !important;
    background: #fff;
    border-radius: 20px;
    border: 1px solid #ccc;
    position: absolute;
    box-shadow: 0 0 5px 1px rgba(57, 76, 96, 0.15),
      0 0 0 1px rgba(53, 71, 90, 0.2);
  }
  .long-handle {
    height: 15px !important;
    width: 5px !important;
  }
  .long-handle-horizontal {
    height: 5px !important;
    width: 15px !important;
  }
 
  .right-handle.bottom-handle {
    transform: translate(-4px, -4px);
  }
  .right-handle.top-handle {
    transform: translate(-4px, 4px);
  }
  .left-handle.bottom-handle {
    transform: translate(4px, -4px);
  }
  .left-handle.top-handle {
    transform: translate(4px, 4px);
  }
  .long-handle-horizontal.bottom-handle {
    left: 50% !important;
    transform: translateX(-8px);
  }
  .long-handle-horizontal.top-handle {
    left: 50% !important;
    transform: translateX(-8px);
  }
  .long-handle.left-handle {
    top: 50% !important;
    transform: translateY(-8px);
  }
  .long-handle.right-handle {
    top: 50% !important;
    transform: translateY(-8px);
  }
  .image-upload-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #imageFile {
    display: none;
  }
  .ql-hidden {
    display: none;
  }
  .ql-out-bottom {
    visibility: hidden;
  }
  .ql-out-top {
    visibility: hidden;
  }
  .ql-tooltip {
    position: absolute;
    transform: translateY(10px);
    background-color: $background-color_4;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 5px #ddd;
    color: $color_2;
    padding: 5px 12px;
    white-space: nowrap;
    a {
      cursor: pointer;
      text-decoration: none;
      line-height: 26px;
    }
    &::before {
      content: "Visit URL:";
      line-height: 26px;
      margin-right: 8px;
    }
    input[type="text"] {
      display: none;
      border: 1px solid #ccc;
      font-size: 13px;
      height: 26px;
      margin: 0px;
      padding: 3px 5px;
      width: 170px;
    }
    a.ql-preview {
      display: inline-block;
      max-width: 200px;
      overflow-x: hidden;
      text-overflow: ellipsis;
      vertical-align: top;
    }
    a.ql-action {
      &::after {
        border-right: 1px solid #ccc;
        content: "Edit";
        margin-left: 16px;
        padding-right: 8px;
      }
    }
    a.ql-remove {
      &::before {
        content: "Remove";
        margin-left: 8px;
      }
    }
  }
  .ql-tooltip.ql-flip {
    transform: translateY(-10px);
  }
  .ql-formats {
    display: inline-block;
    vertical-align: middle;
    &:after {
      clear: both;
      content: "";
      display: table;
    }
  }
  .ql-stroke {
    fill: none;
    stroke: #444;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
  }
  .ql-stroke-miter {
    fill: none;
    stroke: #444;
    stroke-miterlimit: 10;
    stroke-width: 2;
  }
  .ql-fill {
    fill: #444;
  }
  .ql-stroke.ql-fill {
    fill: #444;
  }
  .ql-empty {
    fill: none;
  }
  .ql-even {
    fill-rule: evenodd;
  }
  .ql-thin {
    stroke-width: 1;
  }
  .ql-stroke.ql-thin {
    stroke-width: 1;
  }
  .ql-transparent {
    opacity: 0.4;
  }
  .ql-direction {
    svg {
      &:last-child {
        display: none;
      }
    }
  }
  .ql-direction.ql-active {
    svg {
      &:last-child {
        display: inline;
      }
      &:first-child {
        display: none;
      }
    }
  }
  a {
    text-decoration: underline;
    color: $color_4;
  }
  code {
    background-color: $background-color_2;
    border-radius: 3px;
    font-size: 85%;
    padding: 2px 4px;
  }
  pre.ql-syntax {
    background-color: $background-color_3;
    color: $color_1;
    overflow: visible;
  }
  img {
    max-width: 100%;
  }
  .ql-picker {
    color: $color_2;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
    position: relative;
    vertical-align: middle;
    &:not(.ql-color-picker) {
      &:not(.ql-icon-picker) {
        svg {
          position: absolute;
          margin-top: -9px;
          right: 0;
          top: 50%;
          width: 18px;
        }
      }
    }
  }
  .ql-picker-label {
    cursor: pointer;
    display: inline-block;
    height: 100%;
    padding-left: 8px;
    padding-right: 2px;
    position: relative;
    width: 100%;
    &::before {
      display: inline-block;
      line-height: 22px;
    }
  }
  .ql-picker-options {
    background-color: $background-color_4;
    display: none;
    min-width: 100%;
    padding: 4px 8px;
    position: absolute;
    white-space: nowrap;
    .ql-picker-item {
      cursor: pointer;
      display: block;
      padding-bottom: 5px;
      padding-top: 5px;
    }
  }
  .ql-picker.ql-expanded {
    .ql-picker-label {
      color: $color_3;
      z-index: 2;
      .ql-fill {
        fill: #ccc;
      }
      .ql-stroke {
        stroke: #ccc;
      }
    }
    .ql-picker-options {
      display: block;
      margin-top: -1px;
      top: 100%;
      z-index: 1;
    }
  }
  .ql-color-picker {
    width: 28px;
    .ql-picker-label {
      padding: 2px 4px;
      svg {
        right: 4px;
      }
    }
    .ql-picker-options {
      padding: 3px 5px;
      width: 152px;
    }
    .ql-picker-item {
      border: 1px solid transparent;
      float: left;
      height: 16px;
      margin: 2px;
      padding: 0px;
      width: 16px;
    }
  }
  .ql-icon-picker {
    width: 28px;
    .ql-picker-label {
      padding: 2px 4px;
      svg {
        right: 4px;
      }
    }
    .ql-picker-options {
      padding: 4px 0px;
    }
    .ql-picker-item {
      height: 24px;
      width: 24px;
      padding: 2px 4px;
    }
  }
  .ql-picker.ql-header {
    .ql-picker-label[data-label] {
      &:not([data-label=""]) {
        &::before {
          content: attr(data-label);
        }
      }
    }
    .ql-picker-item[data-label] {
      &:not([data-label=""]) {
        &::before {
          content: attr(data-label);
        }
      }
    }
    width: 98px;
    .ql-picker-label {
      &::before {
        content: "Normal";
      }
    }
    .ql-picker-item {
      &::before {
        content: "Normal";
      }
    }
    .ql-picker-label[data-value="1"] {
      &::before {
        content: "Heading 1";
      }
    }
    .ql-picker-item[data-value="1"] {
      &::before {
        content: "Heading 1";
        font-size: 2em;
      }
    }
    .ql-picker-label[data-value="2"] {
      &::before {
        content: "Heading 2";
      }
    }
    .ql-picker-item[data-value="2"] {
      &::before {
        content: "Heading 2";
        font-size: 1.5em;
      }
    }
    .ql-picker-label[data-value="3"] {
      &::before {
        content: "Heading 3";
      }
    }
    .ql-picker-item[data-value="3"] {
      &::before {
        content: "Heading 3";
        font-size: 1.17em;
      }
    }
    .ql-picker-label[data-value="4"] {
      &::before {
        content: "Heading 4";
      }
    }
    .ql-picker-item[data-value="4"] {
      &::before {
        content: "Heading 4";
        font-size: 1em;
      }
    }
    .ql-picker-label[data-value="5"] {
      &::before {
        content: "Heading 5";
      }
    }
    .ql-picker-item[data-value="5"] {
      &::before {
        content: "Heading 5";
        font-size: 0.83em;
      }
    }
    .ql-picker-label[data-value="6"] {
      &::before {
        content: "Heading 6";
      }
    }
    .ql-picker-item[data-value="6"] {
      &::before {
        content: "Heading 6";
        font-size: 0.67em;
      }
    }
  }
  .ql-picker.ql-font {
    .ql-picker-label[data-label] {
      &:not([data-label=""]) {
        &::before {
          content: attr(data-label);
        }
      }
    }
    .ql-picker-item[data-label] {
      &:not([data-label=""]) {
        &::before {
          content: attr(data-label);
        }
      }
    }
    width: 108px;
    .ql-picker-label {
      &::before {
        content: "Sans Serif";
      }
    }
    .ql-picker-item {
      &::before {
        content: "Sans Serif";
      }
    }
    .ql-picker-label[data-value="serif"] {
      &::before {
        content: "Serif";
      }
    }
    .ql-picker-item[data-value="serif"] {
      &::before {
        content: "Serif";
        font-family: $font-family_1;
      }
    }
    .ql-picker-label[data-value="monospace"] {
      &::before {
        content: "Monospace";
      }
    }
    .ql-picker-item[data-value="monospace"] {
      &::before {
        content: "Monospace";
        font-family: $font-family_2;
      }
    }
  }
  .ql-picker.ql-size {
    .ql-picker-label[data-label] {
      &:not([data-label=""]) {
        &::before {
          content: attr(data-label);
        }
      }
    }
    .ql-picker-item[data-label] {
      &:not([data-label=""]) {
        &::before {
          content: attr(data-label);
        }
      }
    }
    width: 98px;
    .ql-picker-label {
      &::before {
        content: "Normal";
      }
    }
    .ql-picker-item {
      &::before {
        content: "Normal";
      }
    }
    .ql-picker-label[data-value="small"] {
      &::before {
        content: "Small";
      }
    }
    .ql-picker-item[data-value="small"] {
      &::before {
        content: "Small";
        font-size: 10px;
      }
    }
    .ql-picker-label[data-value="large"] {
      &::before {
        content: "Large";
      }
    }
    .ql-picker-item[data-value="large"] {
      &::before {
        content: "Large";
        font-size: 18px;
      }
    }
    .ql-picker-label[data-value="huge"] {
      &::before {
        content: "Huge";
      }
    }
    .ql-picker-item[data-value="huge"] {
      &::before {
        content: "Huge";
        font-size: 32px;
      }
    }
  }
  .ql-color-picker.ql-background {
    .ql-picker-item {
      background-color: $background-color_4;
    }
  }
  .ql-color-picker.ql-color {
    .ql-picker-item {
      background-color: $background-color_5;
    }
  }
  .ql-toolbar {
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-family: $font-family_3;
    padding: 8px;
    + {
      .ql-container {
        border-top: 0px;
      }
    }
  }
  .ql-toolbar.ql-formats {
    margin-right: 15px;
  }
  .ql-toolbar.ql-picker-label {
    border: 1px solid transparent;
  }
  .ql-toolbar.ql-picker-options {
    border: 1px solid transparent;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
  }
  .ql-toolbar.ql-picker.ql-expanded {
    .ql-picker-label {
      border-color: $border-color_1;
    }
    .ql-picker-options {
      border-color: $border-color_1;
    }
  }
  .ql-toolbar.ql-color-picker {
    .ql-picker-item.ql-selected {
      border-color: $border-color_2;
    }
    .ql-picker-item {
      &:hover {
        border-color: $border-color_2;
      }
    }
  }
  .ql-tooltip.ql-editing {
    a.ql-preview {
      display: none;
    }
    a.ql-remove {
      display: none;
    }
    input[type="text"] {
      display: inline-block;
    }
    a.ql-action {
      &::after {
        border-right: 0px;
        content: "Save";
        padding-right: 0px;
      }
    }
  }
  .ql-tooltip[data-mode="link"] {
    &::before {
      content: "Enter link:";
    }
  }
  .ql-tooltip[data-mode="formula"] {
    &::before {
      content: "Enter formula:";
    }
  }
  .ql-tooltip[data-mode="video"] {
    &::before {
      content: "Enter video:";
    }
  }
}
