@import "./react_selector.scss";
@import "./date_picker.scss";


.scrollbar-w-thin{
    scrollbar-width: thin;
}

.terms-box {
    .quill {
        height: 15rem;
        .ql-container {
            height: 12.5rem !important;
            @media (max-width: 485px) and (min-width: 372px) {
                height: 23rem !important;
            }
            @media (max-width: 371px) and (min-width: 324px) {
                height: 21.5rem !important;
            }
            @media (max-width: 323px) and (min-width: 301px) {
                height: 20rem !important;
            }
            @media (max-width: 300px) {
                height: 19rem !important;
            }
        }
    }
}

.terms-box-title{
    .quill {
        height: 8rem;
        margin-bottom: 3px;
        .ql-container {
            height: 5rem !important;
            @media (max-width: 485px) and (min-width: 372px) {
                height: 5rem !important;
            }
            @media (max-width: 371px) and (min-width: 324px) {
                height: 5rem !important;
            }
            @media (max-width: 323px) and (min-width: 301px) {
                height: 5rem !important;
            }
            @media (max-width: 300px) {
                height: 5rem !important;
            }
        }
    } 
}