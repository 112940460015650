.react_selector {
  .css-1jqq78o-placeholder {
    color: black;
    font-size: 13px;
    font-family: Inter;
  }
  .css-t3ipsp-control {
    .css-1dimb5e-singleValue {
      font-size: 0.75rem;
      font-family: Inter;
    }
  }
  .css-olqui2-singleValue {
    font-size: 0.75rem;
    font-family: Inter;
  }
}

.byod_react_selector {
  .css-1fdsijx-ValueContainer {
    width: 100px;
  }
}

.multi_react_select {
  .css-1fdsijx-ValueContainer {
    padding: 0px 6px !important;
  }
  & > :nth-child(3) {
    & > :nth-child(1) {
      & > :nth-child(1) {
        color: #3d3d3d9e;
        font-size: 11.5px !important;
        line-height: 1rem !important;
        font-weight: 600;
      }
    }
  }
  & > :nth-child(4) {
    box-shadow: none !important;
    background-color: white !important;
    padding: 5px !important;
    border: 1px solid #e5e7eb !important;
    border-radius: 5px !important;

    & > :nth-child(1) {
      padding: 0 !important;
    }
  }
  // .css-1k0jre7-menu{
  //   box-shadow: none !important;
  //   background-color: white  !important;
  //   padding: 5px  !important;
  //   border: 1px solid #e5e7eb  !important;
  //   border-radius: 5px  !important;
  // }
  // .css-1cavn08-MenuList{
  //   padding: 0 !important;
  // }
  // #react-select-7-placeholder,  .css-1jqq78o-placeholder {
  //   color: #3d3d3d;
  //   font-size: 11px !important;
  //   line-height: 1rem  !important;
  // }
  div[role="option"] {
    & > :nth-child(1) {
      font-size: 12px !important;
      color: 215, 17%, 55%, 1 !important;
    }
  }
}

.Portfolio_multi_select {
  .css-1fdsijx-ValueContainer {
    padding: 0px 6px !important;
  }
  & > :nth-child(3) {
    & > :nth-child(1) {
      & > :nth-child(1) {
        color: #3d3d3d9e;
        font-size: 11.5px !important;
        line-height: 1rem !important;
        font-weight: 600;
      }
    }
  }
  & > :nth-child(4) {
    box-shadow: none !important;
    background-color: white !important;
    padding: 5px !important;
    border: 1px solid #e5e7eb !important;
    border-radius: 5px !important;

    & > :nth-child(1) {
      padding: 0 !important;
    }
  }
  // .css-1k0jre7-menu{
  //   box-shadow: none !important;
  //   background-color: white  !important;
  //   padding: 5px  !important;
  //   border: 1px solid #e5e7eb  !important;
  //   border-radius: 5px  !important;
  // }
  // .css-1cavn08-MenuList{
  //   padding: 0 !important;
  // }
  // #react-select-7-placeholder,  .css-1jqq78o-placeholder {
  //   color: #3d3d3d;
  //   font-size: 11px !important;
  //   line-height: 1rem  !important;
  // }
  div[role="option"] {
    & > :nth-child(1) {
      font-size: 12px !important;
      color: 215, 17%, 55%, 1 !important;
    }
  }
}
