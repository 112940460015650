.css-13cymwt-control{
    border-color: hsl(220deg 13.04% 90.98%);
    border-radius: 8px;
    min-height: 43px;
}

.css-16xfy0z-control{
  border-color: hsl(220deg 13.04% 90.98%) !important;
  border-radius: 8px !important;
  min-height: 0px !important;
  border-width: 0px !important;
}
.css-3iigni-container div[aria-disabled="true"]{
  border-color: hsl(220deg 13.04% 90.98%) !important;
  border-radius: 8px !important;
  min-height: 0px !important;
  border-width: 0px !important;
}
.suggested_list{
    list-style: none;
    border: 1px solid silver;
    padding: 0;
    min-height: 150px !important;
    height: 100%;
    max-height: 250px !important;
    overflow-y: auto;
    border-radius: 8px !important;
   
  }

  .suggested_list li{
    border-bottom: 1px solid silver;
    padding: 6px 10px;
    cursor: pointer;
    font-weight: 400 !important;
    font-size: 11px !important;
  }

  .select__control--is-disabled{
    background-color: transparent !important;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #9d9d9deb !important;
    border-radius: 8px !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    cursor: not-allowed !important;
    border-color: #e5e7eb  !important;
    min-height: 0 !important;
  }
  .select__control--is-disabled .select__placeholder{
    color: #9d9d9deb  !important;
  }

  .css-13cymwt-control{
    background-color: transparent !important;
    font-size: 0.75rem;
    line-height: 1rem;
    color: black !important;
    border-radius: 8px !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    border-color: #e5e7eb  !important;
    cursor: pointer !important;
    min-height: 0 !important;
  }

  .css-13cymwt-control .select__placeholder{
    color: black  !important;
  }
  .css-t3ipsp-control{
    background-color: transparent !important;
    font-size: 0.90rem;
    line-height: 1rem;
    color: black !important;
    border-radius: 8px !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    cursor: pointer !important;
    border-color: #e5e7eb  !important;
    min-height: 0 !important;
  }
  
  .css-1xc3v61-indicatorContainer {
    padding: 6px 8px !important;
}

.css-15lsz6c-indicatorContainer {
  padding: 6px 8px !important;
}

#react-select-3-placeholder, #react-select-5-placeholder {
  color: #3d3d3d9e;
  font-size: 13px;
  font-family: Inter;
}
.css-1dimb5e-singleValue{
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-weight: 13px !important;
  line-height: inherit;
  color: black !important;
}